// src/components/TikTokShare.js
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';

// Hook to parse query params
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TikTokShare() {
  const query = useQuery();
  const navigate = useNavigate();
  const videoUrl = query.get('videoUrl');
  const tiktokConnected = query.get('tiktokConnected'); // "true" if user is connected

  const [isSharing, setIsSharing] = useState(false);
  const [hasShared, setHasShared] = useState(false);

  const handleShareVideo = async () => {
    if (!videoUrl) {
      toast.error('No video URL provided to share.');
      return;
    }
    setIsSharing(true);
    try {
      await api.post('/tiktok/share-video', { videoUrl });
      toast.success('Successfully shared to TikTok!');
      setHasShared(true);
      // Optionally navigate elsewhere
      // navigate('/dashboard', { replace: true });
    } catch (err) {
      console.error('Error sharing video:', err);
      toast.error('Error sharing to TikTok.');
    } finally {
      setIsSharing(false);
    }
  };

  // Basic UI logic:
  // 1) If no videoUrl, show a message
  // 2) If user is not connected, show 'Connect with TikTok' or a link to your login flow
  // 3) If user is connected, show a 'Share to TikTok' button (or success message if hasShared)

  if (!videoUrl) {
    return (
      <div>
        <h2>TikTok Share</h2>
        <p>No video available to share.</p>
      </div>
    );
  }

  if (tiktokConnected !== 'true') {
    // The user is not connected; they must connect first
    return (
      <div>
        <h2>TikTok Share</h2>
        <p>You are not connected to TikTok. Please connect first.</p>
        {/* 
           Optionally, direct them to /tiktok/login?videoUrl=...&returnUrl=/tiktok/share 
           So they come back here after connecting 
        */}
      </div>
    );
  }

  // If tiktokConnected === 'true'
  return (
    <div>
      <h2>TikTok Share</h2>
      {hasShared ? (
        <p>Video shared successfully: {videoUrl}</p>
      ) : isSharing ? (
        <p>Sharing your video to TikTok...</p>
      ) : (
        <>
          <p>Ready to share video: {videoUrl}</p>
          <button onClick={handleShareVideo}>Share to TikTok</button>
        </>
      )}
    </div>
  );
}

export default TikTokShare;
