// src/components/Video/styles/VideoCardStyles.js
import styled, { css, keyframes } from 'styled-components';
import { FaSpinner, FaPlay } from 'react-icons/fa';
import { media } from '../../../utils/media';
import { Modal, Form, Button } from 'react-bootstrap';

//------------------------------------
// Animations
//------------------------------------
const spin = keyframes`
  to { transform: rotate(360deg); }
`;

//------------------------------------
// Reusable Style Blocks
//------------------------------------

/* 
  1) A helper for bigger clickable area on mobile 
*/
const mobileButtonFocus = css`
  @media (max-width: 768px) {
    padding: 0.75rem 1rem;
    font-size: 1rem;
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;

export const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  display: block;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  cursor: ${({ isPurchasing }) => (isPurchasing ? 'default' : 'pointer')};
`;

// Common button styles with focus-visible and transitions
const buttonStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: 500;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.button};
  outline: none;
  background: none;
  color: ${({ theme }) => theme.colors.textPrimary};

  /* Our new mobile styling mixin: */
  ${mobileButtonFocus};

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.primary};
    outline-offset: 2px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;



const modalTitleStyles = css`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: 600;
`;

//------------------------------------
// Card and Layout
//------------------------------------

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${({ theme }) => theme.spacing.md} 0;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  overflow: hidden;
  transition: ${({ theme }) => theme.transitions.card};

  &:hover {
    transform: translateY(-${({ theme }) => theme.spacing.xs});
    box-shadow: ${({ theme }) => theme.shadows.large};
  }

  ${media.desktop`
    max-width: calc(50% - ${({ theme }) => theme.spacing.md});
  `}

  ${media.tablet`
    max-width: 100%;
  `}
`;

//------------------------------------
// Checkbox and Tooltip
//------------------------------------

export const CheckboxContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.sm};
  left: ${({ theme }) => theme.spacing.sm};
  z-index: 2;

  input[type='checkbox'] {
    width: ${({ theme }) => theme.spacing.md};
    height: ${({ theme }) => theme.spacing.md};
    cursor: pointer;
    accent-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const TooltipText = styled.span`
  visibility: hidden;
  opacity: 0;
  width: 12rem;
  background-color: ${({ theme }) => theme.colors.tooltipBackground};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: ${({ theme }) => theme.spacing.xs};
  position: absolute;
  z-index: 10;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  transition: ${({ theme }) => theme.transitions.tooltip};
  font-size: ${({ theme }) => theme.fontSizes.xs};

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: ${({ theme }) => theme.spacing.xs};
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.tooltipBackground} transparent transparent transparent;
  }
`;

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`;

//------------------------------------
// Thumbnail and Video
//------------------------------------

export const ThumbnailContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.lg};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.lg};
  background-color: ${({ theme }) => theme.colors.primaryBackground};
`;

// export const StyledVideo = styled.video`
//   width: 100%;
//   height: auto;
//   display: block;
//   background-color: ${({ theme }) => theme.colors.darkBackground};
//   cursor: ${({ isPurchasing }) => (isPurchasing ? 'default' : 'pointer')};

// `;

export const FullScreenButton = styled.button`
  ${buttonStyles}
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  padding: 10px;
  z-index: 10;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  svg {
    width: 20px;
    height: 20px;
  }

  /* Increase size on mobile for easier tapping */
  @media (max-width: 768px) {
    bottom: 8px;
    right: 8px;
    padding: 12px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

//------------------------------------
// Play Button Over Video
//------------------------------------

export const PlayButton = styled.button`
  ${buttonStyles}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  width: 60px; /* Increased for easier tapping */
  height: 60px;
  aria-label: "Play video"; /* Accessibility */

  &:hover,
  &:focus-visible {
    background: rgba(0, 0, 0, 0.7);
    outline: 2px solid ${({ theme }) => theme.colors.primary}; /* Better focus state */
    outline-offset: 2px;
  }

  @media (max-width: 768px) {
    width: 70px;
    height: 70px;
  }
`;
export const PlayIcon = styled(FaPlay)`
  font-size: 1.8rem; /* Slightly larger for clarity */
`;

//------------------------------------
// Purchase Overlay
//------------------------------------

export const PurchaseOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: 600;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.sm};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.lg};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.lg};
`;

//------------------------------------
// Download Icon Container
//------------------------------------


export const DownloadIconContainer = styled.button`
  ${buttonStyles}
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.xs};
  right: ${({ theme }) => theme.spacing.xs};
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing.xs};
  z-index: 2;

  &:hover {
    background: rgba(0,0,0,0.8);
  }

  @media (max-width: 768px) {
    padding: 12px;
    bottom: 8px;
    right: 8px;
  }
`;

export const SpinnerIcon = styled(FaSpinner)`
  animation: ${spin} 1s linear infinite;
  color: ${({ theme }) => theme.colors.white};
`;

//------------------------------------
// Content Area
//------------------------------------

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

export const Title = styled.h3`
  margin: 0 0 ${({ theme }) => theme.spacing.xs} 0;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  color: ${({ theme }) => theme.colors.title};
  font-weight: ${({ isPurchased }) => (isPurchased ? '600' : '400')};
  word-break: break-word;
`;

//------------------------------------
// Button Group
//------------------------------------

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xs};
  margin-bottom: ${({ theme }) => theme.spacing.sm};

  @media (max-width: 768px) {
    flex-direction: column; /* stack vertically */
    align-items: stretch;
  }
`;

export const RenameButton = styled(Button)`
  ${buttonStyles}
  background-color: ${({ theme }) => theme.colors.teal};
  color: #fff;
  margin-right: 0.5rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.tealDark || theme.colors.darkBackground};
  }

  @media (max-width: 768px) {
    /* maybe convert to a full-width button on smaller screens? */
    width: 100%;
    margin-right: 0;
  }
`;

export const TrimCropButton = styled(Button)`
  ${buttonStyles}
  background-color: ${({ theme }) => theme.colors.orange};
  color: ${({ theme }) => theme.colors.white};
  margin-right: 0.5rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.orangeDark || theme.colors.darkBackground};
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const DeleteButton = styled(Button)`
  ${buttonStyles}
  background-color: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.errorDark || '#d6273f'};
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;



//------------------------------------
// Text
//------------------------------------

export const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  line-height: 1.5;
`;

export const Message = styled.p`
  color: ${({ theme }) => theme.colors.accents[1] || '#ffc107'};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

//------------------------------------
// Download Progress
//------------------------------------

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.sm};
  height: ${({ theme }) => theme.spacing.md};
`;

export const ProgressBar = styled.div`
  flex-grow: 1;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.lightBackground};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  overflow: hidden;
  margin-right: ${({ theme }) => theme.spacing.xs};
  
  &::after {
    content: '';
    display: block;
    height: 100%;
    width: ${({ progress }) => progress}%;
    background-color: ${({ theme }) => theme.colors.secondary};
    transition: width 0.3s ease;
  }
`;

export const ProgressLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const DownloadCompleteLabel = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.success};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

//------------------------------------
// Modals
//------------------------------------

export const StyledModalHeader = styled(Modal.Header)`
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-bottom: none;

  .modal-title {
    ${modalTitleStyles}
  }

  .close {
    color: ${({ theme }) => theme.colors.textSecondary};
    opacity: 1;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const StyledModalBody = styled(Modal.Body)`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  color: ${({ theme }) => theme.colors.textPrimary};
  padding: ${({ theme }) => theme.spacing.md};
`;

export const StyledModalFooter = styled(Modal.Footer)`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  border-top: none;
`;

export const StyledTrimCropModalHeader = styled(Modal.Header)`
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
  padding: ${({ theme }) => theme.spacing.lg} ${({ theme }) => theme.spacing.md};

  .modal-title {
    color: ${({ theme }) => theme.colors.darkBackground};
    font-size: ${({ theme }) => theme.fontSizes['2xl']};
    font-weight: bold;
  }
`;

export const StyledTrimCropModalBody = styled(Modal.Body)`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.lg}`};
`;

//------------------------------------
// Form Control and Error Text
//------------------------------------

export const StyledFormControl = styled(Form.Control)`
  background-color: ${({ theme }) => theme.colors.inputBackground};
  color: ${({ theme }) => theme.colors.textPrimary};
  border: 1px solid ${({ theme }) => theme.colors.border};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  
  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.inputFocusBackground};
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: none;
  }
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

//------------------------------------
// Custom Modal Overlay
//------------------------------------

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  width: 90%;
  max-width: 500px;
  text-align: center;
  box-shadow: ${({ theme }) => theme.shadows.medium};
`;

export const ModalTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  ${modalTitleStyles};
`;

export const ModalBody = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm};
`;

// Buttons inside Modals
export const ConfirmButton = styled(Button)`
  ${buttonStyles}
  background-color: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.white};
  min-width: 100px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.errorDark || '#d6273f'};
  }
`;

export const CancelButton = styled(Button)`
  ${buttonStyles}
  background-color: ${({ theme }) => theme.colors.lightBackground};
  color: ${({ theme }) => theme.colors.textPrimary};
  min-width: 100px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey || '#e0e0e0'};
  }
`;

export const OverlayLogoImage = styled.img`
  width: 100px;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;



