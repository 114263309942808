// AdminDashboard.jsx

import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import { Link } from 'react-router-dom';
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined';
import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  TextField,
} from '@mui/material';
import styled from 'styled-components';
import { media } from "../../utils/media";


const AdminDashboard = () => {
  // ─────────────────────────────────────────────────────────────────────────────
  // STATE VARIABLES
  // ─────────────────────────────────────────────────────────────────────────────
  const [discountCodes, setDiscountCodes] = useState([]);
  const [users, setUsers] = useState([]);
  const [feedbackList, setFeedbackList] = useState([]);
  const [purchases, setPurchases] = useState([]);

  // Roles
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [assignSuccess, setAssignSuccess] = useState('');
  const [assignError, setAssignError] = useState('');

  // Deletion
  const [deleteSuccess, setDeleteSuccess] = useState('');
  const [deleteError, setDeleteError] = useState('');

  // Feedback
  const [feedbackError, setFeedbackError] = useState('');
  const [deleteFeedbackSuccess, setDeleteFeedbackSuccess] = useState('');

  // Purchases
  const [purchaseError, setPurchaseError] = useState('');

  // Loading states
  const [loadingDiscountCodes, setLoadingDiscountCodes] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingFeedback, setLoadingFeedback] = useState(true);
  const [loadingPurchases, setLoadingPurchases] = useState(true);

  // Courts
  const [courts, setCourts] = useState([]);
  const [selectedCourt, setSelectedCourt] = useState('');
  const [selectedCourtOwner, setSelectedCourtOwner] = useState('');
  const [loadingAssignCourt, setLoadingAssignCourt] = useState(false);
  const [assignCourtSuccess, setAssignCourtSuccess] = useState('');
  const [assignCourtError, setAssignCourtError] = useState('');

  // Influencer Purchases
  const [selectedUserForPurchases, setSelectedUserForPurchases] = useState('');
  const [filteredPurchases, setFilteredPurchases] = useState([]);
  const [loadingFilteredPurchases, setLoadingFilteredPurchases] = useState(false);
  const [purchaseQueryError, setPurchaseQueryError] = useState('');

    // existing court state
    const [newCourtName, setNewCourtName] = useState('');
    const [newCourtHlsUrl, setNewCourtHlsUrl] = useState('');
    const [newCourtCameraName, setNewCourtCameraName] = useState('');
    const [newCourtCameraUrl, setNewCourtCameraUrl] = useState('');

      // ─────────────────────────────────────────────────────────────────────────────
  // 1) Sessions State
  // ─────────────────────────────────────────────────────────────────────────────
  const [sessions, setSessions] = useState([]);

  // For temporarily holding new session inputs
  const [tempSessionName, setTempSessionName] = useState('');
  const [tempDayOfWeek, setTempDayOfWeek] = useState('MONDAY');
  const [tempStartTime, setTempStartTime] = useState('06:00:00');
  const [tempEndTime, setTempEndTime] = useState('12:00:00');
  const [tempActive, setTempActive] = useState(true);

    // A. We'll add a new state for errors or success if you want
    const [exportError, setExportError] = useState('');

  // ─────────────────────────────────────────────────────────────────────────────
  // DATA FETCHING: DISCOUNT CODES, USERS, COURTS, PURCHASES, FEEDBACK
  // ─────────────────────────────────────────────────────────────────────────────

  // 1) Discount Codes
  useEffect(() => {
    const fetchDiscountCodes = async () => {
      try {
        const response = await api.get('/admin/discount-codes/all');
        setDiscountCodes(response.data);
      } catch (error) {
        console.error('Error fetching discount codes:', error);
        setDeleteError('Failed to fetch discount codes.');
      } finally {
        setLoadingDiscountCodes(false);
      }
    };
    fetchDiscountCodes();
  }, []);

  // Add a new session to the sessions array
  const handleAddSession = (e) => {
    e.preventDefault();
    const newSession = {
      sessionName: tempSessionName || `${tempDayOfWeek} Session`,
      dayOfWeek: tempDayOfWeek,
      startTime: tempStartTime,
      endTime: tempEndTime,
      active: tempActive
    };
    setSessions((prev) => [...prev, newSession]);

    // Clear the temporary inputs
    setTempSessionName('');
    setTempDayOfWeek('MONDAY');
    setTempStartTime('06:00:00');
    setTempEndTime('12:00:00');
    setTempActive(true);
  };

  // Remove a session by index
  const handleRemoveSession = (index) => {
    setSessions((prev) => prev.filter((_, i) => i !== index));
  };

   // 4) Submit the court with sessions
   const handleAddCourt = async (e) => {
    e.preventDefault();
    // Build the CourtDto
    const newCourtDto = {
      courtName: newCourtName,
      hlsUrl: newCourtHlsUrl,
      cameraUrls: {
        [newCourtCameraName]: newCourtCameraUrl,
      },
      sessions: sessions,  // <---- attach sessions array here
    };

    try {
      // POST to create the court
      const response = await api.post('/admin/court', newCourtDto);
      console.log('Court created:', response.data);

      // (Optional) Clear the form
      setNewCourtName('');
      setNewCourtHlsUrl('');
      setNewCourtCameraName('');
      setNewCourtCameraUrl('');
      setSessions([]);


    } catch (err) {
      console.error('Error creating court:', err);
      // handle error
    }
  };


  // function to handle exporting revenue records
  const handleExportRevenue = async () => {
    try {
      setExportError('');

      // Make sure to set { responseType: 'arraybuffer' } to receive the file as bytes
      const response = await api.get('/admin/export/purchases', {
        responseType: 'arraybuffer',
      });

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'application/octet-stream' });

      // Create a temporary link to trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'purchases.xlsx'); // The file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error exporting revenue records:', error);
      setExportError('Failed to export revenue records.');
    }
  };

  // 2) Users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/users/admin/users');
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
        setAssignError('Failed to fetch users.');
      } finally {
        setLoadingUsers(false);
      }
    };
    fetchUsers();
  }, []);

  // 3) Courts
  useEffect(() => {
     const fetchAllCourts = async () => {
      try {
        const response = await api.get('/users/admin/courts');
        console.log('Courts:', response.data);
        setCourts(response.data);
      } catch (error) {
        console.error('Error fetching courts:', error);
        // Optionally set an error state
      }
    };
    fetchAllCourts();
  }, []);

  

  // 4) Purchases
  useEffect(() => {
    const fetchUserPurchases = async () => {
      try {
        const res = await api.get('/users/admin/purchases');
        setPurchases(res.data);
        console.log('Purchases:', res.data);
      } catch (err) {
        console.error("Error fetching purchases:", err);
        setPurchaseError('Failed to fetch user purchases.');
      } finally {
        setLoadingPurchases(false);
      }
    };
    fetchUserPurchases();
  }, []);

  // 5) Feedback
  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const response = await api.get('/feedback/all'); 
        setFeedbackList(response.data);
      } catch (error) {
        console.error('Error fetching feedback:', error);
        setFeedbackError('Failed to fetch feedback.');
      } finally {
        setLoadingFeedback(false);
      }
    };
    fetchFeedback();
  }, []);

  // ─────────────────────────────────────────────────────────────────────────────
  // FETCH FILTERED PURCHASES AFTER INFLUENCER CODE
  // ─────────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    const fetchFilteredPurchases = async () => {
      if (!selectedUserForPurchases) return; // No user selected; skip
      setLoadingFilteredPurchases(true);
      try {
        const response = await api.get(`/admin/after/influencer/${selectedUserForPurchases}`);
        setFilteredPurchases(response.data);
      } catch (error) {
        console.error("Error fetching filtered purchases:", error);
        setPurchaseQueryError('Failed to fetch purchases for selected user.');
      } finally {
        setLoadingFilteredPurchases(false);
      }
    };
    fetchFilteredPurchases();
  }, [selectedUserForPurchases]);

  // ─────────────────────────────────────────────────────────────────────────────
  // EVENT HANDLERS
  // ─────────────────────────────────────────────────────────────────────────────

  // 1) Delete a discount code
  const handleDelete = async (id) => {
    try {
      await api.delete(`/admin/discount-codes/${id}`);
      setDiscountCodes((prev) => prev.filter((code) => code.id !== id));
      setDeleteSuccess('Discount code deleted successfully.');
    } catch (error) {
      console.error('Error deleting discount code:', error);
      setDeleteError('Failed to delete discount code.');
    }
  };

  // 2) Delete feedback
  const handleDeleteFeedback = async (feedbackId) => {
    try {
      await api.delete(`/feedback/delete/${feedbackId}`);
      setFeedbackList((prev) => prev.filter((fb) => fb.id !== feedbackId));
      setDeleteFeedbackSuccess('Feedback deleted successfully.');
    } catch (error) {
      console.error('Error deleting feedback:', error);
      setFeedbackError('Failed to delete feedback.');
    }
  };

  // 3) Assign role to user
  const handleAssignRole = async (e) => {
    e.preventDefault();
    if (!selectedUser || !selectedRole) {
      setAssignError('Please select both a user and a role.');
      return;
    }

    try {
      setLoadingAssign(true);
      await api.post('/users/admin/add-role', {
        userName: selectedUser,
        roleName: selectedRole.toUpperCase(),
      });
      setAssignSuccess(`Role "${selectedRole}" assigned to "${selectedUser}" successfully.`);

      // Refresh user list to see updated roles
      const response = await api.get('/users/admin/users');
      setUsers(response.data);

      // Reset form
      setSelectedUser('');
      setSelectedRole('');
    } catch (error) {
      console.error('Error assigning role:', error);
      setAssignError(error.response?.data?.message || 'Failed to assign role.');
    } finally {
      setLoadingAssign(false);
    }
  };

  // 4) Assign court ownership
  const handleAssignCourtOwnership = async (e) => {
    e.preventDefault();

    if (!selectedCourt || !selectedCourtOwner) {
      setAssignCourtError('Please select both a court and a user.');
      return;
    }

    try {
      setLoadingAssignCourt(true);
      await api.post('/users/admin/assign-court', {
        courtId: selectedCourt,
        username: selectedCourtOwner,
      });
      setAssignCourtSuccess(`Court ownership assigned to ${selectedCourtOwner}.`);
    } catch (error) {
      console.error('Error assigning court ownership:', error);
      setAssignCourtError(error.response?.data?.message || 'Failed to assign court ownership.');
    } finally {
      setLoadingAssignCourt(false);
      setSelectedCourt('');
      setSelectedCourtOwner('');
    }
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // RENDER
  // ─────────────────────────────────────────────────────────────────────────────
  return (
    <Container>
      <Title variant="h3" gutterBottom>
        Admin Dashboard
      </Title>

      {/* Discount Codes Section */}
      <Section>
        <SectionHeader>
          <Typography variant="h5">Discount Codes</Typography>
          <ResponsiveButton
            component={Link}
            to="/admin/discount-code/create"
            variant="contained"
            color="secondary"
          >
            Create New Discount Code
          </ResponsiveButton>
        </SectionHeader>

        <TableWrapper>
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell>Code</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Value</StyledTableCell>
                <StyledTableCell>Expiration Date</StyledTableCell>
                <StyledTableCell>Usage Limit</StyledTableCell>
                <StyledTableCell>Times Used</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingDiscountCodes ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : discountCodes.map((code) => (
                <TableRow key={code.id}>
                  <TableCell>{code.code}</TableCell>
                  <TableCell>{code.description || 'N/A'}</TableCell>
                  <TableCell>{code.discountType || 'N/A'}</TableCell>
                  <TableCell>{code.value || 'N/A'}</TableCell>
                  <TableCell>
                    {code.expirationDate
                      ? new Date(code.expirationDate).toLocaleDateString()
                      : 'N/A'}
                  </TableCell>
                  <TableCell>{code.usageLimit || 'N/A'}</TableCell>
                  <TableCell>{code.timesUsed || '0'}</TableCell>
                  <TableCell>
                    <Tooltip title="Edit Discount Code">
                      <StyledLinkButton
                        component={Link}
                        to={`/admin/discount-code/update/${code.id}`}
                        variant="outlined"
                        color="secondary"
                      >
                        Edit
                      </StyledLinkButton>
                    </Tooltip>
                    <Tooltip title="Delete Discount Code">
                      <StyledDeleteButton
                        variant="outlined"
                        color="error"
                        onClick={() => handleDelete(code.id)}
                      >
                        Delete
                      </StyledDeleteButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>

          {!loadingDiscountCodes && discountCodes.length === 0 && !deleteError && (
            <NoDataMessage>No discount codes available.</NoDataMessage>
          )}
          {deleteError && <ErrorText>{deleteError}</ErrorText>}
        </TableWrapper>
      </Section>

      {/* User Roles Section */}
      <Section>
        <SectionHeader>
          <Typography variant="h5">User Roles</Typography>
        </SectionHeader>
        <Form onSubmit={handleAssignRole}>
          <FormControlStyled variant="outlined" required>
            <InputLabel id="select-user-label">Select User</InputLabel>
            <StyledSelect
              labelId="select-user-label"
              value={selectedUser}
              label="Select User"
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              {loadingUsers ? (
                <MenuItem value="">
                  <em>Loading...</em>
                </MenuItem>
              ) : (
                users.map((user) => (
                  <MenuItem key={user.id} value={user.username}>
                    {user.username} ({user.email}) - Current Role: {user.roles.join(', ')}
                  </MenuItem>
                ))
              )}
            </StyledSelect>
          </FormControlStyled>

          <FormControlStyled variant="outlined" required>
            <InputLabel id="select-role-label">Select Role</InputLabel>
            <StyledSelect
              labelId="select-role-label"
              value={selectedRole}
              label="Select Role"
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              <MenuItem value="ADMIN">Admin</MenuItem>
              <MenuItem value="USER">User</MenuItem>
              {/* Add more roles as needed */}
            </StyledSelect>
          </FormControlStyled>

          <StyledSubmitButton
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loadingAssign}
          >
            {loadingAssign ? <CircularProgress size={24} color="inherit" /> : 'Assign Role'}
          </StyledSubmitButton>
        </Form>
      </Section>

      <Section>
        <SectionHeader>
          <Typography variant="h5">Add Court</Typography>
        </SectionHeader>
        
        <Form onSubmit={handleAddCourt}>
          {/* Court Name, HLS, Camera as before */}
          <TextField
            label="Court Name"
            value={newCourtName}
            onChange={(e) => setNewCourtName(e.target.value)}
            required
          />
          <TextField
            label="HLS URL"
            value={newCourtHlsUrl}
            onChange={(e) => setNewCourtHlsUrl(e.target.value)}
          />
          {/* One camera for example */}
          <TextField
            label="Camera Name"
            value={newCourtCameraName}
            onChange={(e) => setNewCourtCameraName(e.target.value)}
          />
          <TextField
            label="Camera RTSP URL"
            value={newCourtCameraUrl}
            onChange={(e) => setNewCourtCameraUrl(e.target.value)}
          />
          
          {/* ───────────────────────────────────────────────────────────────────── */}
          {/* Sessions Sub-Form */}
          {/* ───────────────────────────────────────────────────────────────────── */}
          <Typography variant="h6">Add Court Sessions</Typography>
          <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
            
            {/* Session Name (optional) */}
            <TextField
              label="Session Name"
              value={tempSessionName}
              onChange={(e) => setTempSessionName(e.target.value)}
              placeholder="(Optional) e.g. Monday Morning"
            />
            
            {/* Day of Week */}
            <TextField
              select
              label="Day of Week"
              value={tempDayOfWeek}
              onChange={(e) => setTempDayOfWeek(e.target.value)}
              style={{ width: '150px' }}
            >
              {['MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY','SUNDAY']
                .map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))
              }
            </TextField>

            {/* Start Time */}
            <TextField
              label="Start Time (HH:mm:ss)"
              value={tempStartTime}
              onChange={(e) => setTempStartTime(e.target.value)}
            />

            {/* End Time */}
            <TextField
              label="End Time (HH:mm:ss)"
              value={tempEndTime}
              onChange={(e) => setTempEndTime(e.target.value)}
            />

            {/* Active */}
            <TextField
              select
              label="Active?"
              value={tempActive}
              onChange={(e) => setTempActive(e.target.value === 'true')}
              style={{ width: '100px' }}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </TextField>

            <Button variant="contained" onClick={handleAddSession}>
              Add Session
            </Button>
          </div>

          {/* Show the current sessions */}
          {sessions.length > 0 && (
            <div style={{ marginTop: '16px' }}>
              <Typography>Current Sessions:</Typography>
              <ul>
                {sessions.map((s, index) => (
                  <li key={index}>
                    {s.sessionName} – {s.dayOfWeek}, {s.startTime}-{s.endTime}, Active={String(s.active)}
                    <Button color="error" onClick={() => handleRemoveSession(index)}>
                      Remove
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Submit the entire court */}
          <StyledSubmitButton type="submit" variant="contained" color="secondary">
            Create Court
          </StyledSubmitButton>
        </Form>
      </Section>

      {/* Court Ownership Assignment Section */}
      <Section>
        <SectionHeader>
          <Typography variant="h5">Court Ownership</Typography>
        </SectionHeader>

        <Form onSubmit={handleAssignCourtOwnership}>
          {/* Select Court */}
          <FormControlStyled variant="outlined" required>
            <InputLabel id="select-court-label">Select Court</InputLabel>
            <StyledSelect
              labelId="select-court-label"
              value={selectedCourt}
              label="Select Court"
              onChange={(e) => setSelectedCourt(e.target.value)}
            >
              {courts.length === 0 ? (
                <MenuItem value="">
                  <em>No courts found</em>
                </MenuItem>
              ) : (
                courts.map((court) => (
                  <MenuItem key={court.courtId} value={court.courtId}>
                    {court.courtName} ({court.courtId})
                  </MenuItem>
                ))
              )}
            </StyledSelect>
          </FormControlStyled>

          {/* Select User */}
          <FormControlStyled variant="outlined" required>
            <InputLabel id="select-court-owner-label">Select User</InputLabel>
            <StyledSelect
              labelId="select-court-owner-label"
              value={selectedCourtOwner}
              label="Select User"
              onChange={(e) => setSelectedCourtOwner(e.target.value)}
            >
              {loadingUsers ? (
                <MenuItem value="">
                  <em>Loading...</em>
                </MenuItem>
              ) : (
                users.map((user) => (
                  <MenuItem key={user.id} value={user.username}>
                    {user.username} ({user.email})
                  </MenuItem>
                ))
              )}
            </StyledSelect>
          </FormControlStyled>

          <StyledSubmitButton
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loadingAssignCourt}
          >
            {loadingAssignCourt ? <CircularProgress size={24} color="inherit" /> : 'Assign Court Ownership'}
          </StyledSubmitButton>
        </Form>
      </Section>

      {/* Purchases AFTER Influencer Code Usage */}
      <Section>
        <Typography variant="h5">Purchases After Influencer Code Usage</Typography>
        <FormControlStyled variant="outlined">
          <InputLabel id="select-user-purchases-label">Select User</InputLabel>
          <StyledSelect
            labelId="select-user-purchases-label"
            value={selectedUserForPurchases}
            label="Select User"
            onChange={(e) => setSelectedUserForPurchases(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.username} ({user.email})
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControlStyled>

        {loadingFilteredPurchases ? (
          <CircularProgress />
        ) : purchaseQueryError ? (
          <ErrorText>{purchaseQueryError}</ErrorText>
        ) : filteredPurchases.length > 0 ? (
          <TableWrapper>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Purchase ID</StyledTableCell>
                  <StyledTableCell>Purchase Date</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell>
                  {/* Add more cells as needed */}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPurchases.map((purchase) => (
                  <TableRow key={purchase.id}>
                    <TableCell>{purchase.id}</TableCell>
                    <TableCell>
                      {new Date(purchase.purchaseDate).toLocaleString()}
                    </TableCell>
                    <TableCell>{purchase.price}</TableCell>
                    {/* Additional fields */}
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableWrapper>
        ) : (
          <Typography>
            No purchases found for selected user after influencer code usage.
          </Typography>
        )}
      </Section>

      {/* User Purchases Section */}
      <Section>
        <SectionHeader>
          <Typography variant="h5">User Purchases</Typography>
        </SectionHeader>
        <TableWrapper>
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell>Purchase ID</StyledTableCell>
                <StyledTableCell>Court Name</StyledTableCell>
                <StyledTableCell>Username</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Video Title</StyledTableCell>
                <StyledTableCell>Purchase Date</StyledTableCell>
                <StyledTableCell>Price</StyledTableCell>
                <StyledTableCell>Video Link</StyledTableCell>
                <StyledTableCell>DiscountCode</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingPurchases ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                purchases.map((purchase) => (
                  <TableRow key={purchase.id}>
                    <TableCell>{purchase.id}</TableCell>
                    <TableCell>
                      {purchase.video.courtName || 'N/A'}
                    </TableCell>
                    <TableCell>{purchase.user.username}</TableCell>
                    <TableCell>{purchase.user.email}</TableCell>
                    <TableCell>{purchase.video.title}</TableCell>
                    <TableCell>
                      {purchase.video.purchaseDate
                        ? new Date(purchase.purchaseDate).toLocaleString()
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {purchase.video.price != null
                        ? `$${purchase.video.price.toFixed(2)}`
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {purchase.video.preSignedS3Url ? (
                        <a
                          href={purchase.video.preSignedS3Url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Video
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell>
                      {purchase.discountCode || 'N/A'}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </StyledTable>
          {!loadingPurchases && purchases.length === 0 && !purchaseError && (
            <NoDataMessage>No purchases available.</NoDataMessage>
          )}
          {purchaseError && <ErrorText>{purchaseError}</ErrorText>}
        </TableWrapper>
      </Section>

      {/* REVENUE RECORDS EXPORT SECTION */}
      <Section>
        <SectionHeader>
          <Typography variant="h5">Revenue Records</Typography>
        </SectionHeader>

        {exportError && <ErrorText>{exportError}</ErrorText>}

        <ExportButton onClick={handleExportRevenue}>
          <CloudDownloadOutlined style={{ marginRight: '8px' }} />
          Export Revenue to Excel
        </ExportButton>
      </Section>

      {/* Feedback Management Section */}
      <Section>
        <SectionHeader>
          <Typography variant="h5">Feedback Management</Typography>
        </SectionHeader>
        <TableWrapper>
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell>Username</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Content</StyledTableCell>
                <StyledTableCell>Rating</StyledTableCell>
                <StyledTableCell>Timestamp</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingFeedback ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                feedbackList.map((feedback) => (
                  <TableRow key={feedback.id}>
                    <TableCell>{feedback.username}</TableCell>
                    <TableCell>{feedback.email}</TableCell>
                    <TableCell>{feedback.feedbackContent || 'N/A'}</TableCell>
                    <TableCell>{feedback.rating || 'N/A'}</TableCell>
                    <TableCell>
                      {feedback.timestamp
                        ? new Date(feedback.timestamp).toLocaleString()
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Delete Feedback">
                        <StyledDeleteButton
                          variant="outlined"
                          color="error"
                          onClick={() => handleDeleteFeedback(feedback.id)}
                        >
                          Delete
                        </StyledDeleteButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </StyledTable>
          {!loadingFeedback && feedbackList.length === 0 && !feedbackError && (
            <NoDataMessage>No feedback available.</NoDataMessage>
          )}
          {feedbackError && <ErrorText>{feedbackError}</ErrorText>}
        </TableWrapper>
      </Section>

      {/* ───────────────────────────────────────────────────────────────────────── */}
      {/* SNACKBAR NOTIFICATIONS */}
      {/* ───────────────────────────────────────────────────────────────────────── */}

      {/* Discount Code Deletion */}
      <Snackbar
        open={!!deleteSuccess}
        autoHideDuration={6000}
        onClose={() => setDeleteSuccess('')}
      >
        <Alert
          onClose={() => setDeleteSuccess('')}
          severity="success"
          sx={{ width: '100%' }}
        >
          {deleteSuccess}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!deleteError}
        autoHideDuration={6000}
        onClose={() => setDeleteError('')}
      >
        <Alert
          onClose={() => setDeleteError('')}
          severity="error"
          sx={{ width: '100%' }}
        >
          {deleteError}
        </Alert>
      </Snackbar>

      {/* Role Assignment */}
      <Snackbar
        open={!!assignSuccess}
        autoHideDuration={6000}
        onClose={() => setAssignSuccess('')}
      >
        <Alert
          onClose={() => setAssignSuccess('')}
          severity="success"
          sx={{ width: '100%' }}
        >
          {assignSuccess}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!assignError}
        autoHideDuration={6000}
        onClose={() => setAssignError('')}
      >
        <Alert
          onClose={() => setAssignError('')}
          severity="error"
          sx={{ width: '100%' }}
        >
          {assignError}
        </Alert>
      </Snackbar>

      {/* Feedback Deletion */}
      <Snackbar
        open={!!deleteFeedbackSuccess}
        autoHideDuration={6000}
        onClose={() => setDeleteFeedbackSuccess('')}
      >
        <Alert
          onClose={() => setDeleteFeedbackSuccess('')}
          severity="success"
          sx={{ width: '100%' }}
        >
          {deleteFeedbackSuccess}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!feedbackError}
        autoHideDuration={6000}
        onClose={() => setFeedbackError('')}
      >
        <Alert
          onClose={() => setFeedbackError('')}
          severity="error"
          sx={{ width: '100%' }}
        >
          {feedbackError}
        </Alert>
      </Snackbar>

      {/* Court Ownership */}
      <Snackbar
        open={!!assignCourtSuccess}
        autoHideDuration={6000}
        onClose={() => setAssignCourtSuccess('')}
      >
        <Alert
          onClose={() => setAssignCourtSuccess('')}
          severity="success"
          sx={{ width: '100%' }}
        >
          {assignCourtSuccess}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!assignCourtError}
        autoHideDuration={6000}
        onClose={() => setAssignCourtError('')}
      >
        <Alert
          onClose={() => setAssignCourtError('')}
          severity="error"
          sx={{ width: '100%' }}
        >
          {assignCourtError}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminDashboard;

//
// ─────────────────────────────────────────────────────────────────────────────
// STYLED COMPONENTS
// ─────────────────────────────────────────────────────────────────────────────
//

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: 40px;
  max-width: 1200px;
  margin: 40px auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  ${media.mobile`
    padding: 20px;
    margin: 20px auto;
  `}
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.accentBlueGray};
  margin-bottom: 20px !important;
  text-align: center;
`;

const Section = styled.div`
  margin-bottom: 60px;

  ${media.mobile`
    margin-bottom: 30px;
  `}
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  ${media.mobile`
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  `}
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  margin-bottom: 20px;
`;

const StyledTable = styled(Table)`
  min-width: 700px;
  & .MuiTableCell-root {
    border-bottom: 1px solid ${({ theme }) => theme.colors.accentBlueGray};
  }
`;

const StyledTableCell = styled(TableCell)`
  color: ${({ theme }) => theme.colors.accentBlueGray};
  font-weight: bold;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
  margin: 0 auto;

  ${media.mobile`
    gap: 15px;
  `}
`;

const FormControlStyled = styled(FormControl)`
  & .MuiInputLabel-outlined {
    color: ${({ theme }) => theme.colors.accentBlueGray};
  }
  & .MuiOutlinedInput-root {
    background-color: #fff;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.accentBlueGray};
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.accentBlueGray};
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.accentBlueGray};
  }
`;

const StyledSelect = styled(Select)`
  & .MuiSelect-select {
    color: ${({ theme }) => theme.colors.accentBlueGray};
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.accentBlueGray};
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.accentBlueGray};
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.accentBlueGray};
  }
`;

const StyledLinkButton = styled(Button)`
  margin-right: 8px;
  border-color: ${({ theme }) => theme.colors.accentTeal};
  color: ${({ theme }) => theme.colors.accentTeal};

  &:hover {
    background-color: ${({ theme }) => theme.colors.accentTeal};
    color: #fff;
  }

  ${media.mobile`
    margin-right: 0;
    width: 100%;
  `}
`;

const StyledDeleteButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.accentOrange};
  color: ${({ theme }) => theme.colors.accentOrange};

  &:hover {
    background-color: ${({ theme }) => theme.colors.accentOrange};
    color: #fff;
  }

  ${media.mobile`
    width: 100%;
    margin-top: 8px;
  `}
`;

const ResponsiveButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondaryButton};
  color: #fff;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accentOrange};
  }

  ${media.mobile`
    padding: 8px 16px;
    font-size: 0.9rem;
    width: 100%;
  `}
`;

const StyledSubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondaryButton};
  color: #fff;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accentOrange};
  }

  ${media.mobile`
    padding: 10px 20px;
    font-size: 0.9rem;
  `}
`;

const NoDataMessage = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.accentBlueGray};
  margin-top: 20px;
`;

const ErrorText = styled(Typography)`
  text-align: center;
  color: red;
  margin-top: 20px;
`;

const ExportButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondaryButton};
  color: #fff;
  margin-top: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accentOrange};
  }

  svg {
    font-size: 1.2rem;
  }

  ${media.mobile`
    width: 100%;
    margin-top: 10px;
  `}
`;

