export const getCourtName = (title) => {
    if (!title) return 'Unknown Court';
    const match = title.match(/^.*?_(.*?)_video_/);
    return match && match[1] ? match[1] : 'Unknown Court';
  };

  export const formatTime = (timeInSeconds) => {
    const totalSeconds = Math.floor(timeInSeconds);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
  
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
    }
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };
  