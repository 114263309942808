import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/Auth/AuthProvider';
import PrivateRoute from './components/Auth/PrivateRoute';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import VideoPage from './pages/VideoPage';
import RegisterPage from './pages/RegisterPage';
import VideoDetailsPage from './pages/VideoDetailsPage';
import DashboardPage from './pages/DashboardPage';
import PaymentCancelled from './pages/PaymentCancelled';
import ConfirmationPage from './pages/ConfirmationPage';
import Landing from './pages/Landing';
import AdminDashboard from './components/Dashboard/AdminDashboard';
import CreateDiscountCode from './components/discountCode/CreateDiscountCode';
import EditDiscountCode from './components/discountCode/EditDiscountCode';
import Unauthorized from './components/Unauthorized';
import { theme } from './components/common/theme';
import { ThemeProvider } from 'styled-components';
import Layout from './components/common/Layout';
import AuthLayout from './components/common/AuthLayout';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import VerifyEmail from './components/VerifyEmail';
import Contact from './components/Contact';
import VideoTrimmer from './components/Video/VideoTrimmer';
import CookiePolicy from './components/policies/CookiePolicy';
import TermsOfService from './components/policies/TermsOfService';
import ReturnPolicy from './components/policies/ReturnPolicy';
import PrivacyPolicy from './components/policies/PrivacyPolicy';
import Disclaimer from './components/policies/Disclaimer';
import TermsOfServicee from './components/TermsOfService';
import PrivacyPolicyy from './components/PrivacyPolicy';
import Courts from './components/courts/Courts';
import TikTokLoginButton from './components/TikTokLoginButton';
import TikTokShare from './components/TikTokShare';
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => (
  <AuthProvider>
    <ThemeProvider theme={theme}> 
      <Router>
      <Routes>
  {/* Public Routes */}
      <Route path="/" element={<Landing />} />
      <Route 
            path="/register" 
            element={
              <AuthLayout headerProps={registerPropHeaderObject}>
                <RegisterPage />
              </AuthLayout>
            } 
          />
      {/* Public Routes without Navbar */}
      <Route 
            path="/login" 
            element={
              <AuthLayout headerProps={loginPropHeaderObject}>
                <LoginPage />
              </AuthLayout>
            } 
          />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/tiktok/login" element={<AuthLayout><TikTokLoginButton /> </AuthLayout>} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/tiktok/share" element={<Layout><TikTokShare /></Layout>} />
      <Route path="/explore" element={<Layout><VideoPage /></Layout>} />
      <Route path="/video/:id" element={<VideoDetailsPage />} />
      <Route path="/confirmation" element={<Layout><ConfirmationPage /></Layout>} />
      <Route path="/payment-cancelled" element={<PaymentCancelled />} />
      <Route path="/contact" element={<Layout><Contact /></Layout>} />
      <Route path="/privacy-policy" element={<Layout><PrivacyPolicy/></Layout>} />
        <Route path="/cookie-policy" element={<Layout><CookiePolicy/></Layout>} />
        <Route path="/terms-of-service" element={<Layout><TermsOfService/></Layout>} />
        <Route path="/terms-of-servicee" element={<Layout><TermsOfServicee/></Layout>} />
        <Route path="/return-policy" element={<Layout><ReturnPolicy/></Layout>} />
        <Route path="/disclaimer" element={<Layout><Disclaimer/></Layout>} />
        <Route path="/privacy-policyy" element={<Layout><PrivacyPolicyy/></Layout>} />

  {/* Protected Routes */}
  <Route
    path="/dashboard"
    element={
      <PrivateRoute>
        <Layout>
        <DashboardPage />
        </Layout>
      </PrivateRoute>
    }
  />
  <Route 
  path="/videos/:videoId/trim" 
  element={
    <PrivateRoute>
      <Layout>
      <VideoTrimmer />
      </Layout>
    </PrivateRoute>
  } />
    <Route
    path="/admin/dashboard"
    element={
      <PrivateRoute requiredRole="ADMIN">
        <Layout>
        <AdminDashboard />
        </Layout>
      </PrivateRoute>
    }
  />
      <Route
    path="/admin/discount-code/create"
    element={
      <PrivateRoute requiredRole="ADMIN">
        <Layout>
        <CreateDiscountCode />
        </Layout>
      </PrivateRoute>
    }
  />

<Route
    path="/admin/discount-code/update/:id"
    element={
      <PrivateRoute requiredRole="ADMIN">
        <Layout>
        <EditDiscountCode />
        </Layout>
      </PrivateRoute>
    }
  />

  <Route path="/courts" element={<Layout><Courts /></Layout>} />

</Routes>

      </Router>
    </ThemeProvider>

  </AuthProvider>
);

export default App;

const loginPropHeaderObject = {
  // logoSrc: `${process.env.PUBLIC_URL}/1.svg`,
  // altText: 'Logo',
  // title: 'Welcome back!',
  // subtitle: 'Login to your account to continue.',
};

const registerPropHeaderObject = {
  // logoSrc: `${process.env.PUBLIC_URL}/1.svg`,
  altText: 'Logo',
  title: 'Create an account',
  subtitle: 'to access your videos',
};
